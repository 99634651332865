export const resources = [
    {
      id: 0,
      title: "MyFrontier Mobile App",
      image: require("../assets/images/frontiericon.jpg"),
      type: "img",
      url: "https://www.figma.com/proto/AaEe7Ce6ObhRlGJlCW2Kb1/MyFrontier-App?page-id=29%3A43&type=design&node-id=1613-2350&viewport=3632%2C532%2C0.66&t=KgKGYkBBtdU3okRg-8&scaling=scale-down&starting-point-node-id=1613%3A2350&disable-default-keyboard-nav=1&hide-ui=1",
      description: "Easily navigate the MyFrontier mobile app directly from your browser. Built with Figma."
    }
 ]
