import * as React from 'react'
import { resources } from './resources'
import NavBar from './NavBar'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'

const ButtonLink = React.forwardRef((props, ref) => (
  <Button ref={ref} size="small" {...props} role={undefined} />
))

export default function Dashboard() {
  return (
    <>
      <NavBar />
      <div id='dashboard-container'>
        {resources.map((resource) => (
          <div key={resource.id} id='appcard-container'>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component={resource.type}
                height="140"
                image={resource.image}
                alt={resource.title}
              />
              <CardContent>
                <Typography
                  sx={{
                    fontFamily: 'Bandwidth Display',
                    color: '#141928'
                  }}
                  gutterBottom 
                  variant="h5"
                  component="div"
                >
                  {resource.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Object Sans',
                    color: '#000000'
                  }}
                  variant="body2"
                  color="text.secondary"
                >
                  {resource.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Link
                  href={resource.url}
                  component={ButtonLink}
                  target="_blank"
                  rel="noopener"
                  variant='button'
                  underline='none'
                >
                 Launch
                </Link>
                <Button disabled size="small">Report Bug</Button>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>
    </>
  )
}
