import { Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import Layout from './components/Layout'
import { useSelector } from 'react-redux'
import { selectUsers } from './store/usersSlice'

export default function App() {
  const user = useSelector(selectUsers)
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={
          user.currentUser ? (
            <Dashboard />
          ) : (
            <Login />
          )}
        />
      </Route>
    </Routes>
  )
}