import React from 'react'
import { auth } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { selectUsers, setUser } from '../store/usersSlice'
import { signOut } from 'firebase/auth'

export default function NavBar() {
  const user = useSelector(selectUsers)
  const dispatch = useDispatch()
  console.log(user)

  function handleSignOut() {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('Are you sure you want to sign out?')) {
      signOut(auth).then(() => {
        dispatch(setUser(null))
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  return (
    <>
      <header className='header'>
        <section className='title'>
          <h3>Agent Dashboard</h3>
        </section>
        <section className='sign-in-out'>
          <div className='current-user'>
            Signed in as: 
            <span id='user-displayname'>
              {user.currentUser.name}
            </span>
            <img
              src={user.currentUser.avatar}
              alt='user avatar'
              style={{
                height: 'auto',
                maxWidth: '24px',
                maxHeight: '24px',
                borderRadius: '100px',
                marginRight: 0,
                marginLeft: '0.5em',
                padding: 0
              }}
            />
          </div>
          <button onClick={handleSignOut}>
            LOGOUT
          </button>
        </section>
      </header>
    </>
  )
}
