import React, { useState } from 'react'
import GoogleButton from 'react-google-button'
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth'
import { auth } from '../firebase'
import { useDispatch } from 'react-redux'
import { setUser } from '../store/usersSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import FullPageLoader from './FullPageLoader'

export default function Login() {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'
  const dispatch = useDispatch()

  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(setUser({ id: user.uid, name: user.displayName, email: user.email, avatar: user.photoURL }))
      navigate(from, { replace: true })
    } else {
      dispatch(setUser(null))
    }
    if(isLoading) {setIsLoading(false)}
  })

  function handleSignIn() {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
    .catch((error) => {
      const errorMessage = error.errorMessage
      console.log(errorMessage)
    })
  }

  return (
    <>
      { isLoading && <FullPageLoader bg="#141928"></FullPageLoader> }
      <div className='wrapper'>
        <main className='login'>
          <section className='login-title'>
            Dashboard Login
          </section>
          <section className='login-text'>
            Access to these resources require you to be signed into your company-provided email address. 
            Click the button below to get started!
          </section>
          <GoogleButton onClick={handleSignIn} />
        </main>
      </div>
    </>
  )
}